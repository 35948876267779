<template>
  <layout :showTopBanner="false" :showSidebar="false">
    <template #header>
      <app-header
        :showRightButtons="false"
        :leftLogoPersist="true"
      ></app-header>
    </template>
    <div class="content">
      <v-card class="container" rounded="xl">
        <h1 class="top-title">Create your profile</h1>
        <user-create-form
          @submit="submit"
          :no-cancel="true"
          :user="user"
          v-if="!isLoading"
        />
      </v-card>
    </div>
  </layout>
</template>
<script>
import UserCreateForm from "../components/UserForm.vue";
import Header from "../../app/components/headers/DefaultHeader";
import DefaultLayout from "../../app/components/layouts/DefaultLayout";
import { createUser, getOrgByDomain, joinOrg } from "./user-create.js";
import { getUserOrgDomain } from "../../app/utils/auth-helper";

export default {
  name: "UserCreate",
  metaInfo: {
    title: "New User",
  },
  components: {
    "user-create-form": UserCreateForm,
    "app-header": Header,
    layout: DefaultLayout,
  },
  data() {
    return {
      org: {},
      isLoading: true,
    };
  },
  async mounted() {
    if (this.appMode === "enterprise") {
      await this.getUserOrg();
    }
    this.isLoading = false;
  },
  computed: {
    appMode() {
      return process.env.VUE_APP_MODE;
    },
    user() {
      return {
        org: this.org.name,
        location: {
          city: this.org.location ? this.org.location.city : "",
          country: this.org.location ? this.org.location.country : "",
          state: this.org.location ? this.org.location.state : "",
        },
      };
    },
  },
  methods: {
    async getUseOrg() {
      try {
        const orgDomain = await getUserOrgDomain();
        if (!orgDomain) {
          throw new Error("Company domain not found.");
        }
        const org = await getOrgByDomain(orgDomain);
        this.org = org;
        this.isLoading = false;
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting your organization info. Please contact your administrator."
        );
      }
    },
    async submit({ user, skills, values, interests }) {
      try {
        if (!this.org && this.appMode === "enterprise") {
          this.$store.dispatch(
            "callAlert",
            "No organization found. “Please contact your administrator."
          );
          return;
        }
        const newUser = await createUser({ user, skills, values, interests });
        if (this.appMode === "enterprise") {
          const orgId = this.org.id;
          await joinOrg({ userId: newUser.id, orgId });
        }
        await this.$store.dispatch("loadUserInfo");
        await this.$store.dispatch("loadUserOrganization");

        this.$router.push("/users/" + newUser.id);
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while creating user profile."
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 700px;
  width: 60%;
  flex: 1;
  margin: 0 auto;
  margin-top: 15px;
  padding: 25px 40px;
  .top-title {
    color: $inqliGreen;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 95%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>

import sendRequest from "../../app/utils/send-request-helper";
import uploadImage from "../../app/utils/upload-image-helper";

export const createUser = async ({ user, skills, values, interests, mode }) => {
  let imageUrl = "";
  // check and upload image
  if (user.avatar) {
    imageUrl = await uploadImage(user.avatar);
  }
  let url = `${process.env.VUE_APP_URL}/resources/users`;
  if (mode) {
    url = `${url}?mode=${mode}`;
  }
  // Create user
  const newUser = await sendRequest({
    url,
    method: "POST",
    isAuth: true,
    body: {
      ...user,
      avatar: imageUrl,
      privacy_policy: {
        version: "1",
        date: "2020-01-27 22:21:03.099440",
      },
      terms_of_use: {
        version: "1",
        date: "2020-01-27 22:21:03.099440",
      },
    },
  });
  // Create user skills, interests and values
  if (Array.isArray(skills) && skills.length > 0) {
    await sendRequest({
      url: `${process.env.VUE_APP_URL}/relationships/has-skill`,
      method: "POST",
      isAuth: true,
      body: {
        id: newUser.id,
        skills,
      },
    });
  }
  if (Array.isArray(interests) && interests.length > 0) {
    await sendRequest({
      url: `${process.env.VUE_APP_URL}/relationships/has-interest`,
      method: "POST",
      isAuth: true,
      body: {
        id: newUser.id,
        interests,
      },
    });
  }
  if (Array.isArray(values) && values.length > 0) {
    await sendRequest({
      url: `${process.env.VUE_APP_URL}/relationships/has-value`,
      method: "POST",
      isAuth: true,
      body: {
        id: newUser.id,
        values: values.map((value) => value.toLowerCase()),
      },
    });
    // join org for user
    // trigger to reload user orgs and user info (get from store)
  }
  return newUser;
};

export const getOrgByDomain = async (domain) => {
  const data = await sendRequest({
    url: `/resources/organizations/domain/${domain}`,
    method: "GET",
    isAuth: true,
  });
  return data;
};

export const joinOrg = async ({ userId, orgId }) => {
  await sendRequest({
    url: `/relationships/member?source_id=${userId}&dest_id=${orgId}`,
    method: "POST",
    isAuth: true,
  });
};

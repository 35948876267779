var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      attrs: { showTopBanner: false, showSidebar: false },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("app-header", {
                attrs: { showRightButtons: false, leftLogoPersist: true }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-card",
            { staticClass: "container", attrs: { rounded: "xl" } },
            [
              _c("h1", { staticClass: "top-title" }, [
                _vm._v("Create your profile")
              ]),
              !_vm.isLoading
                ? _c("user-create-form", {
                    attrs: { "no-cancel": true, user: _vm.user },
                    on: { submit: _vm.submit }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }